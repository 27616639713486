import React from 'react'
import PropTypes from 'prop-types'
import styled from '@emotion/styled'
import { Background } from "react-imgix";

import houseimg from '../../../assets/images/house.png'

const ImageHeaderWrapper = styled.div`
  ${tw`flex flex-wrap mb-16`}

  @media screen and (max-width: 64em) {
    ${tw`flex-col-reverse`}
  }
`

const ImageHeaderLeftSide = styled.div`
  width: 35%;
  height: calc(100vh - 120px);

  @media screen and (max-width: 64em) {
    width: 100%;
    height: auto;
    ${tw`px-5 pt-12`}
  }
`

const ImageHeaderRigtSide = styled.div`
  ${tw`flex-no-grow flex-shrink relative`}
  width: 65%;
  height: calc(100vh - 120px);

  & > div {
    height: 100%;
  }

  @media screen and (max-width: 64em) {
    width: 100%;
    height: 480px;
  }

  @media screen and (max-width: 40em) {
    height: 300px;
  }
`

const ImageHeaderLeftWrapper = styled.div`
  ${tw`flex flex-col justify-center`}
  max-width: 386px;
  height: calc(100vh - 120px);
  margin-right: auto;
  margin-left: auto;

  @media screen and (max-width: 64em) {
    width: 100%;
    max-width: 100%;
    height: auto;
  }
`

const H1 = styled.h1`
  ${tw`font-montserrat text-black text-4xl font-light m-0 mb-1`}
  line-height: 46px;

  p {
    margin: 0px;
  }
`

const H2 = styled.h2`
  ${tw`font-montserrat text-grey text-4xl font-light m-0`}
  line-height: 46px;

  p {
    margin: 0px;
  }
`

const Description = styled.div`
  ${tw`font-raleway text-grey text-blog-text text-lg leading-normal font-medium m-0 mt-6`}

  p {
    margin: 0px;
  }
`

const HouseImg = styled.img`
  width: 180px;
  margin-bottom: 20px;
`

const ImageHeader = ({
  title,
  subtitle,
  description,
  image,
  house
}) => (
  <ImageHeaderWrapper>
    <ImageHeaderLeftSide>
      <ImageHeaderLeftWrapper>
        {house ? <HouseImg src={houseimg} alt="" /> : null}
        <H1 dangerouslySetInnerHTML={{ __html: title }} />
        {(subtitle) ? <H2 dangerouslySetInnerHTML={{ __html: subtitle }} /> : null}
        {(description) ? <Description dangerouslySetInnerHTML={{ __html: description }} /> : null}
      </ImageHeaderLeftWrapper>
    </ImageHeaderLeftSide>

    <ImageHeaderRigtSide>
      <Background
        src={image}
      />
    </ImageHeaderRigtSide>
  </ImageHeaderWrapper>
)

ImageHeader.propTypes = {
  // Page title
  title: PropTypes.string.isRequired,
  // Page subtitle
  subtitle: PropTypes.string,
  // Image details
  image: PropTypes.string.isRequired,
  description: PropTypes.string,
  house: PropTypes.bool
}

ImageHeader.defaultProps = {
  subtitle: '',
  description: '',
  house: false
}

export default ImageHeader
